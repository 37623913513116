<i18n locale="th" lang="yaml" >
page.title : "{name} :: แก้ไขข้อมูลอะไหล่"
page.description : "แก้ไขข้อมูลของอะไหล่ SKU : {name} ในระบบ"
update_part.success : "ทำการแก้ไขข้อมูลอะไหล่ \"SKU : {name}\" ในระบบเรียบร้อย"
update_part.confirm.title : "ยืนยันการแก้ข้อมูลอะไหล่"
update_part.confirm.message : "คุณต้องการแก้ไขข้อมูลอะไหล่นี้ ?"
</i18n>

<template>
	<div class="page page-padding">
		<my-page-header
			:title="$t('page.title',{ name: part.sku })"
			:description="$t('page.description',{ name: part.sku })"/>
		<PartForm
			ref="formRef"
			:loading="loading"
			mode="update"
			@submit="handleSubmit">
			<template v-slot:submitSlot="{ formLoading }">
				<a-button
					:disabled="formLoading"
					icon="left"
					size="large"
					:style="{ 'margin-right': '4px' }"
					@click="goBack">
					{{$t('common.back')}}
				</a-button>
				<a-button
					:loading="formLoading"
					type="info"
					html-type="submit"
					class="btn-submit"
					icon="edit"
					size="large">
					{{$t('common.save')}}
				</a-button>
			</template>
		</PartForm>
	</div>
</template>

<script>
import PageMixin from '@mixins/PageMixin.vue'
import PartForm from '@components/part/PartForm.vue'
import axios from "axios"
import {mapActions} from "vuex"

export default {
	page() {
		return {
			title : this.$t('page.title',{name:this.part.sku})
		}
	}	,
	components : {
		PartForm
	} ,
	mixins: [PageMixin] ,
	data() {
		return {
			part: {} ,
			loading : false,
		}
	} ,
	watch: {
		$route : 'fetchData' ,
	} ,
	mounted() {
		this.fetchData()
	} ,
	methods : {
		...mapActions('drone',['fetchParts']),
		fetchData() {
			const partId = this.$route.params.id;
			if (!partId)
				return

			this.showPageLoading(true)

			this.clearBreadcrumbParams('partName')
			axios.get("/api/parts/"+partId).then((response)=>{
				this.part = response.data.data.part;
				this.$refs.formRef.updatePartForm(this.part)

				this.addBreadcrumbParams({partName : this.part.name})
			}).catch((error) => {
				this.fetchError(error);
			}).finally(()=>{
				this.hidePageLoading()
			})
		} ,
		handleSubmit(formData) {
			this.$confirm({
				title : this.$t('update_part.confirm.title') ,
				content : this.$t('update_part.confirm.message') ,
				maskClosable : true,
				onOk : () => {
					this.loading = true
					axios.put("/api/parts/"+this.part.id,formData).then((response) => {
						const newPart = response.data.data.part

						this.fetchParts()
						this.$message.success(this.$t('update_part.success',{"name" : newPart.sku}))
						this.$open({name : 'part/view',	params : {id : newPart.id}});
					}).catch((error) => {
						this.$refs.formRef.formErrors(error)
					}).finally(()=>{
						this.loading = false;
					});
				}
			})
		} ,
		goBack() {
			this.$open({name:'part/view',params:{id:this.part.id}})
		}
	}
}
</script>
